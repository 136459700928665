<template>
  <div>
    <b-col cols="12">
      <h2>Групповое управление рейсами</h2>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "TripsGroupManaging"
}
</script>

<style scoped>

</style>